
(function ($) {
	"use strict";
	$.fn.angularFunction = function () {

		// Sticky menu
		var $window = $(window);
		$window.on('scroll', function () {
			var scroll = $window.scrollTop();
			if (scroll < 300) {
				$(".sticky").removeClass("is-sticky");
			} else {
				$(".sticky").addClass("is-sticky");
			}
		});

		
		


		// tooltip active js
		$('[data-toggle="tooltip"]').tooltip();


		// Background Image JS start
		var bgSelector = $(".bg-img");
		bgSelector.each(function (index, elem) {
			var element = $(elem),
				bgSource = element.data('bg');
			element.css('background-image', 'url(' + bgSource + ')');
		});


		// Off Canvas Open close
		$(".mobile-menu-btn").on('click', function () {
			$("body").addClass('fix');
			$(".off-canvas-wrapper").addClass('open');
		});

		$(".btn-close-off-canvas,.off-canvas-overlay").on('click', function () {
			$("body").removeClass('fix');
			$(".off-canvas-wrapper").removeClass('open');
		});

		// offcanvas mobile menu
		var $offCanvasNav = $('.mobile-menu'),
			$offCanvasNavSubMenu = $offCanvasNav.find('.dropdown');

		/*Add Toggle Button With Off Canvas Sub Menu*/
		$offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i></i></span>');

		/*Close Off Canvas Sub Menu*/
		$offCanvasNavSubMenu.slideUp();

		/*Category Sub Menu Toggle*/
		$offCanvasNav.on('click', 'li a, li .menu-expand', function (e) {
			var $this = $(this);
			if (($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
				e.preventDefault();
				if ($this.siblings('ul:visible').length) {
					$this.parent('li').removeClass('active');
					$this.siblings('ul').slideUp();
				} else {
					$this.parent('li').addClass('active');
					$this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
					$this.closest('li').siblings('li').find('ul:visible').slideUp();
					$this.siblings('ul').slideDown();
				}
			}
		});

		// product carousel active
		$('.product-carousel-4_2').slick({
			speed: 1000,
			slidesToShow: 4,
			rows: 2,
			adaptiveHeight: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="pe-7s-angle-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="pe-7s-angle-right"></i></button>',
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					arrows: false,
					rows: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					arrows: false,
					rows: 1
				}
			}]
		});

		// blog carousel active start
		$('.group-list-carousel--3').slick({
			autoplay: true,
			speed: 1000,
			rows: 3,
			slidesToShow: 3,
			adaptiveHeight: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="pe-7s-angle-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="pe-7s-angle-right"></i></button>',
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: 1
				}
			}]
		});

		// blog carousel active start
		$('.blog-carousel-2').slick({
			speed: 1000,
			dots: true,
			arrows: false
		});


		// testimonial cariusel active js
		$('.testimonial-content-carousel').slick({
			arrows: false,
			asNavFor: '.testimonial-thumb-carousel'
		});


		// product details slider nav active
		$('.testimonial-thumb-carousel').slick({
			slidesToShow: 3,
			asNavFor: '.testimonial-content-carousel',
			centerMode: true,
			arrows: false,
			centerPadding: 0,
			focusOnSelect: true
		});


		// blog carousel active
		$('.blog-carousel-active').slick({
			autoplay: true,
			speed: 1000,
			slidesToShow: 3,
			adaptiveHeight: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="pe-7s-angle-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="pe-7s-angle-right"></i></button>',
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: 1
				}
			}]
		});


		//  Hot deals carousel active start
		$('.deals-carousel-active').slick({
			autoplay: true,
			speed: 1000,
			slidesToShow: 3,
			adaptiveHeight: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="pe-7s-angle-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="pe-7s-angle-right"></i></button>',
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: 2
				}
			},
			{
				breakpoint: 576,
				settings: {
					arrows: false,
					slidesToShow: 1
				}
			}]
		});


		// brand logo carousel active js
		$('.brand-logo-carousel').slick({
			speed: 1000,
			slidesToShow: 5,
			adaptiveHeight: true,
			prevArrow: '<button type="button" class="slick-prev"><i class="pe-7s-angle-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="pe-7s-angle-right"></i></button>',
			responsive: [{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					arrows: false
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}]
		});

		//nice select active start
		// $('select').niceSelect();


		// Image zoom effect
		$('.img-zoom').zoom();


		// offcanvas minicart button js
		$(".minicart-btn").on('click', function () {
			$("body").addClass('fix');
			$(".minicart-inner").addClass('show')
		})

		$(".offcanvas-close, .minicart-close,.offcanvas-overlay").on('click', function () {
			$("body").removeClass('fix');
			$(".minicart-inner").removeClass('show')
		})


		// Data countdown active js
		$('[data-countdown]').each(function () {
			var $this = $(this),
				finalDate = $(this).data('countdown');
			$this.countdown(finalDate, function (event) {
				$this.html(event.strftime('<div class="single-countdown"><span class="single-countdown__time">%D</span><span class="single-countdown__text">Days</span></div><div class="single-countdown"><span class="single-countdown__time">%H</span><span class="single-countdown__text">Hours</span></div><div class="single-countdown"><span class="single-countdown__time">%M</span><span class="single-countdown__text">Mins</span></div><div class="single-countdown"><span class="single-countdown__time">%S</span><span class="single-countdown__text">Secs</span></div>'));
			});
		});

		// quantity change js
		$('.pro-qty').prepend('<span class="dec qtybtn">-</span>');
		$('.pro-qty').append('<span class="inc qtybtn">+</span>');
		$('.qtybtn').on('click', function () {

			var $button = $(this);
			var oldValue = $button.parent().find('input').val();
			var quantity = $button.parent().find('input').data('quantity');
			if ($button.hasClass('inc')) {
				if(quantity > parseFloat(oldValue)) {
					var newVal = parseFloat(oldValue) + 1;
				} else {
					var newVal = parseFloat(oldValue);
				}
			} else {
				// Don't allow decrementing below zero
				if (oldValue > 1) {
					var newVal = parseFloat(oldValue) - 1;
				} else {
					newVal = 1;
				}
			}
			$button.parent().find('input').val(newVal);
		});


		// product view mode change js
		$('.product-view-mode a').on('click', function (e) {
			e.preventDefault();
			var shopProductWrap = $('.shop-product-wrap');
			var viewMode = $(this).data('target');
			$('.product-view-mode a').removeClass('active');
			$(this).addClass('active');
			shopProductWrap.removeClass('grid-view list-view').addClass(viewMode);
		})


		// Checkout Page accordion
		$("#create_pwd").on("change", function () {
			$(".account-create").slideToggle("100");
		});

		$("#ship_to_different").on("change", function () {
			$(".ship-to-different").slideToggle("100");
		});


		// Payment Method Accordion
		$('input[name="paymentmethod"]').on('click', function () {
			var $value = $(this).attr('value');
			$('.payment-method-details').slideUp();
			$('[data-method="' + $value + '"]').slideDown();
		});


		// Scroll to top active js
		$(window).on('scroll', function () {
			if ($(this).scrollTop() > 600) {
				$('.scroll-top').removeClass('not-visible');
			} else {
				$('.scroll-top').addClass('not-visible');
			}
		});
		$('.scroll-top').on('click', function (event) {
			$('html,body').animate({
				scrollTop: 0
			}, 1000);
		});


		// Search trigger js
		$(".search-trigger").on('click', function () {
			$(".header-search-box").toggleClass('search-box-open');
		})


		// User Changeable Access
		var activeId = $("#instafeed"),
			myTemplate = '<div class="instagram-item"><a href="{{link}}" target="_blank" id="{{id}}"><img src="{{image}}" /></a><div class="instagram-hvr-content"><span class="tottallikes"><i class="fa fa-heart"></i>{{likes}}</span><span class="totalcomments"><i class="fa fa-comments"></i>{{comments}}</span></div></div>';

		if (activeId.length) {
			var userID = activeId.attr('data-userid'),
				accessTokenID = activeId.attr('data-accesstoken'),

				userFeed = new Instafeed({
					get: 'user',
					userId: userID,
					accessToken: accessTokenID,
					resolution: 'standard_resolution',
					template: myTemplate,
					sortBy: 'least-recent',
					limit: 15,
					links: false
				});
			userFeed.run();
		}

		// Instagram feed carousel active
		$(window).on('load', function () {
			var instagramFeed = $(".instagram-carousel");
			instagramFeed.imagesLoaded(function () {
				instagramFeed.slick({
					slidesToShow: 5,
					slidesToScroll: 2,
					dots: false,
					arrows: false,
					responsive: [{
						breakpoint: 480,
						settings: {
							slidesToShow: 2,
						}
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 991,
						settings: {
							slidesToShow: 4,
						}
					}
					]
				})
			});
		})
	};
})(jQuery);
$(window).angularFunction();